import { gql, MutationHookOptions, useMutation } from '@apollo/client';

import { Mutation, MutationCreateUploadSignedUrlArgs } from '../../generated/types';

export const CREATE_UPLOAD_SIGNED_URL = gql`
  mutation createUploadSignedUrl(
    $entityType: EntityType!
    $userUuid: String!
    $boardUuid: String
    $jobUuid: String
    $filename: String!
    $mimetype: String!
    $lastModified: Float!
    $size: Int!
  ) {
    createUploadSignedUrl(
      entityType: $entityType
      userUuid: $userUuid
      boardUuid: $boardUuid
      jobUuid: $jobUuid
      filename: $filename
      mimetype: $mimetype
      lastModified: $lastModified
      size: $size
    ) {
      uuid
      uploadSignedUrl
      isDeleted
      filename
      resource
      mimetype
      formattedMimetype
      createdAt
    }
  }
`;

export const useCreateUploadSignedUrl = (
  options?: MutationHookOptions<Pick<Mutation, 'createUploadSignedUrl'>, MutationCreateUploadSignedUrlArgs>
) =>
  useMutation<Pick<Mutation, 'createUploadSignedUrl'>, MutationCreateUploadSignedUrlArgs>(
    CREATE_UPLOAD_SIGNED_URL,
    options
  );
