// https://react-dropzone.org/#!/Examples
import { useCallback, useId, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

import { Colors } from '../../../styles/colors';
import { File } from '../../../components/atoms/File';
import downLoadingZone from '../../../assets/lotties/downloading-zone.json';
import { Lottie } from '../../atoms/Lottie';
import generateID from '../../../helper/id';

export const UploadZone = ({ children, copy, files, setFiles, onFileDrop }: UploadZoneProps) => {
  const [temporayFiles, setTemporayFiles] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  // On files dropped we loop on each ones.
  // If a file is valid we try to upload it to s3.
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setIsLoading(true);

      // Loop on each files dropped
      // Add a temporary file to the list
      const updatedFilesList = [...files];

      for (const acceptedFile of acceptedFiles) {
        const id = generateID();

        setTemporayFiles((files) => [
          ...files,
          {
            lastModified: acceptedFile.lastModified,
            name: acceptedFile.name,
            mimetype: acceptedFile.type,
            size: acceptedFile.size,
            uuid: id,
            isNew: true,
            status: 'uploading',
          },
        ]);

        try {
          const { uuid, mimetype, formattedMimetype } = await onFileDrop(acceptedFile);
          updatedFilesList.push({
            lastModified: acceptedFile.lastModified,
            name: acceptedFile.name,
            mimetype: mimetype,
            formattedMimetype: formattedMimetype,
            size: acceptedFile.size,
            uuid: uuid,
            isNew: true,
            status: 'uploaded',
          });
          setFiles(updatedFilesList);

          setTemporayFiles((files) => {
            return files.filter((file) => file.uuid !== id);
          });
        } catch (error) {
          console.error(error);
          setTemporayFiles((files) => {
            return files.map((file) => {
              if (file.uuid === id) {
                return {
                  ...file,
                  status: 'error',
                };
              }
              return file;
            });
          });
        }
      }

      setIsLoading(false);
    },
    [files, setFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  async function handleRemoveClick(uuid: string) {
    setFiles(files.filter((file) => file.uuid !== uuid));
  }

  const newFilesList = files.map((file, i) => (
    <File
      key={i}
      uuid={file.uuid}
      filename={file.name}
      status={file.status}
      isNew={true}
      formattedMimetype={file.formattedMimetype}
      mimeType={file.formattedMimetype}
      onDeleteClick={() => handleRemoveClick(file.uuid)}
    />
  ));

  const temporaryFilesList = temporayFiles.map((file) => (
    <File
      key={file.uuid}
      uuid={file.uuid}
      filename={file.name}
      status={file.status}
      isNew={true}
      formattedMimetype={file.formattedMimetype}
      mimeType={file.mimeType}
    />
  ));

  return (
    <WrapperUpload>
      {/* <pre>{JSON.stringify(isLoading, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(files, null, 2)}</pre> */}
      <WrapperUploadInfo {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <WrapperUploadInfoIcon>
          {isLoading ? (
            <Lottie key="loading" autoplay={true} animationData={downLoadingZone} />
          ) : (
            <Lottie key="notLoading" autoplay={false} animationData={downLoadingZone} />
          )}
        </WrapperUploadInfoIcon>
        {copy}
      </WrapperUploadInfo>
      <WrapperFiles>
        {children}
        {newFilesList}
        {temporaryFilesList}
      </WrapperFiles>
    </WrapperUpload>
  );
};

const WrapperUpload = styled.div`
  padding: 5px;
  border: 1px dotted ${Colors.Primary};
  margin-bottom: 30px;
  cursor: pointer;
  width: 100%;
  max-width: 900px;

  &:hover {
    border: 1px solid ${Colors.Contrast};
  }
`;

const WrapperUploadInfo = styled.div`
  text-align: center;
  cursor: pointer;
`;

const WrapperFiles = styled.div`
  > div {
    margin-bottom: 10px;
  }
`;

const WrapperUploadInfoIcon = styled.div`
  width: 10em;
  margin: auto;
`;

interface UploadZoneProps extends React.HTMLAttributes<HTMLDivElement> {
  // path "bailcalcus.pdf"
  // lastModified 1649411375355
  // lastModifiedDate Fri Apr 08 2022 10:49:35 GMT+0100 (British Summer Time) {}
  // name bailcalcus.pdf
  // size 5540929
  // type "application/pdf"
  copy: React.ReactNode;
  files: any[];
  setFiles: (files: any[]) => void;
  onFileDrop: (file: File) => Promise<{ uuid: string; mimetype: string; formattedMimetype: string }>;
}
