import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationArchiveFileArgs } from '../../generated/types';

export const ARCHIVE_FILE = gql`
  mutation ArchiveFile(
    $entityType: EntityType!
    $userUuid: String!
    $boardUuid: String
    $jobUuid: String
    $fileUuid: String!
    $isDeleted: Boolean!
  ) {
    archiveFile(
      entityType: $entityType
      userUuid: $userUuid
      boardUuid: $boardUuid
      jobUuid: $jobUuid
      fileUuid: $fileUuid
      isDeleted: $isDeleted
    ) {
      uuid
      isDeleted
      filename
      resource
      createdAt
    }
  }
`;

export const useArchiveFile = (options?: MutationHookOptions<Pick<Mutation, 'archiveFile'>, MutationArchiveFileArgs>) =>
  useMutation<Pick<Mutation, 'archiveFile'>, MutationArchiveFileArgs>(ARCHIVE_FILE, {
    // onCompleted: (data) => {
    //   console.log('onCompleted', data);
    // },
    ...options,
  });
